html,
body,
#root {
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue',
    Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
}
body {
  margin: 0;
}

*::-webkit-scrollbar-track {
  background: #ddd;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 7px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: #aaa;
}

*::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.leaflet-container {
  height: 100%;
  width: 100%;
}

.custom-vehicle-marker-container {
  height: 20px;
  width: 20px;
  border-radius: 100%;
}

.custom-vehicle-marker-container .vehicle-marker {
  display: flex;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #fff;
}
div.vehicle-marker {
  top: -17.5px;
  left: -17.5px;
}
.custom-vehicle-marker-container .vehicle-marker div.vehicle-label {
  position: absolute;
  top: 36px;
  background: #fff;
  padding: 1px 2px;
  border-radius: 2px;
}
.ideal-path-marker-container {
  height: 0px;
  width: 0px;
}
.ideal-path-marker-container .ideal-path-marker {
  height: 5px;
  width: 5px;
  border-radius: 50%;
}

/* used in kpi json editor for displaying select box above it  */
.jsoneditor-contextmenu {
  z-index: 0 !important;
}
